import React from 'react';

// components
import Layout from '../components/layout';

const ContactPage = () => {
  return(
    <Layout>
      <div className="container__box">
        <h1><span className="heading__naples">Kontakt</span></h1>
        <p>Sugerowany kontakt</p>
        <ul>
          <li>tel. <a href="tel:512294324">512 294 324</a></li>
          <li>mail: <a href="mailto:biuro@tkd-it.pl">biuro@tkd-it.pl</a></li>
        </ul>
        <b>Przed wizytą, prosimy o kontakt telefoniczny.</b>
      </div>
      <div className="container__graybox">
        <p className="mt-0">Jakub Libertowski</p>
        <ul>
          <li>tel. <a href="tel:730798768">730 798 768</a></li>
          <li>mail: <a href="mailto:jakub.libertowski@tkd-it.pl">jakub.libertowski@tkd-it.pl</a></li>
        </ul>
        <p>Tomasz Mizak</p>
        <ul>
          <li>tel. <a href="tel:512294324">512 294 324</a></li>
          <li>mail: <a href="mailto:tomasz.mizak@tkd-it.pl">tomasz.mizak@tkd-it.pl</a></li>
        </ul>
        <p>Dawid Stolecki</p>
        <ul>
          <li>mail: <a href="mailto:dawid.stolecki@tkd-it.pl">dawid.stolecki@tkd-it.pl</a></li>
        </ul>
      </div>
    </Layout>
  );
}

export default ContactPage;